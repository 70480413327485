<template>
  <v-container class="ma-10" fluid>
    <h1>Темы:</h1>
    <!-- <v-radio-group v-model="radioGroup" @change="toggleTheme" mandatory>
      <v-radio label="Светлая" class="pa-2" value="1"></v-radio>
      <v-radio label="Темная" class="pa-2" value="2"></v-radio>
    </v-radio-group> -->
    <v-btn @click="toggleTheme">Изменить тему</v-btn>
  </v-container>
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {
      radioGroup: 1
    };
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("useDarkTheme", this.$vuetify.theme.dark.toString());
    }
  }
};
</script>
